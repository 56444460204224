.contact {
  padding-left: 24px;
  padding-right: 24px;
  /* background: rgba(4, 43, 235, 0.2); */
  display: flex;
  flex-direction: column;
  padding-bottom: 228px;
}

@media (min-width: 600px) {
  .contact {
    padding-top: 28px;
    padding-left: 64px;
    padding-right: 64px;
  }
}

.polaroid {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 600px) {
  .polaroid {
    width: 300px;
  }
}
