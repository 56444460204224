h1 {
  margin-top: 32px;
  font-family: Soley;
  color: #f1efef;
  font-size: 28px;
}

p {
  font-family: Archivo Narrow;
  color: #f1efef;
  font-size: 18px;
}

.page {
  background-color: #0d0d0f;
  width: 100%;
  min-height: 1200px;
  display: flex;
}

.content {
  position: relative;
  background-image: url("./static/BackgroundImage.png");
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
}

@media (min-width: 600px) {
  .content {
    width: 738px;
  }
}

/* LOGO */
.logo {
  position: absolute;
  width: 28px;
  left: 8px;
  top: 8px;
}

@media (min-width: 600px) {
  .logo {
    width: 60px;
    left: -60px;
  }
}
/* HEADER */
.header {
  width: 100%;
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 600px) {
  .header {
    flex-direction: row;
  }
}

.header a {
  display: flex;
  color: #f1efef;
  font-family: Soley;
  text-decoration: none;
  font-size: 22px;
  margin-bottom: 22px;
}

a.selected {
  color: #ec6f21;
}

/* FOOTER */
.footer {
  position: absolute;
  bottom: 28px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 600px) {
  .footer {
    padding-left: 64px;
  }
}

.footer p {
  font-size: 16px;
  margin: 0;
}
