@font-face {
  font-family: "Soley";
  src: local("Soley"), url("./fonts/Soley.ttf") format("truetype");
  font-weight: normal;
}

a {
  color: #f1efef;
}

body {
  background-color: #f1efef;
  margin: 0;
  font-family: "Archivo Narrow", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
